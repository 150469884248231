<template>
  <div v-if="isCurrentUserDealer && imageExists" class="container-fluid section-items banner-container bg-light d-none d-sm-none d-md-block">
    <div class="container">
      <div class="row">
        <img :class="{
          'banner-img_pointer': link
        }" @click="openBannerLink" class="banner-img" alt="Main page banner" :src="imgSrc" @error="imageExists = false"/>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {base_url} from "@/lib/axios";
import {MAIN_PAGE_BANNER} from "@/config/common_image_codes";
import router from "@/router";

export default {
  name: "Banner",
  data: () => ({
    link: '',
    imageExists: true
  }),
  async mounted() {
    const {data: {status, data}} = await api.banner.getBanner(MAIN_PAGE_BANNER);

    if (status === 'ok' && data.link) {
      this.link = data.link
    }
  },
  methods: {
    openBannerLink() {
      if ((new RegExp("^(?!www\\.|(?:http|ftp)s?://|[A-Za-z]:\\\\|//).*").test(this.link))) {
        router.push(this.link)
      } else {
        window.open(this.link, '_blank');
      }
    }
  },
  computed: {
    imgSrc() {
      return `${base_url}/api/common-images/${MAIN_PAGE_BANNER}`;
    }
  }
}
</script>

<style scoped>
.banner-container {
  margin-top: 1.88rem;
}

.banner-img_pointer {
  cursor: pointer;
}

.row {
  justify-content: center;
}
</style>