<template>
  <div v-if="popularProducts && popularProducts.length > 0" class="container">
    <div class="col">
      <h2 class="title"><span>Популярные товары</span></h2>
      <div class="popular-products row d-flex" :class="{}">
        <div class="popular-products__item col-lg-3 col-md-4 col-sm-6 col-12" :key="key" v-for="(product, key) in popularProducts">
          <product-item class="main-page-popular-products" :product="product" ></product-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductItem from "@/components/catalog/ProductItem";

export default {
  components: {
    'product-item': ProductItem
  },
  name: "PopularProducts",
  computed: {
    ...mapGetters('main_page', ['popularProducts'])
  }
}
</script>

<style lang="sass" scoped>

@media (max-width: 485px)
  .popular-products
    flex-direction: column
    align-items: center

    &__item:not(:last-child)
      margin-bottom: 40px
</style>