<template>
  <div v-if="isCurrentUserDealer" class="container-fluid section-items bg-light d-none d-sm-none d-md-block">
    <div class="container">
      <div class="row">
        <div class="col">
          <p style="margin-left:14.2pt; text-align:center">&nbsp;</p>

          <p style="margin-left:14.2pt; text-align:center"><strong><span style="font-size:16px">Уважаемые партнеры!</span></strong></p>

          <p style="text-align:justify"><span style="font-size:16px"><span style="color:#cc0000"><strong>ООО &laquo;ЛЛК-Интернешнл&raquo; информирует вас об изменении сроков оформления заказов</strong></span> рекламно-сувенирной и полиграфической продукции на ЛУКМАРКЕТ <span style="color:#cc0000"><strong>и прекращении приема заказов на ЛУКМАРКЕТ с 15 декабря 2020 года до 15 марта 2021 года.</strong></span></span></p>

          <p style="text-align:justify"><span style="font-size:16px">С целью оптимизации сроков производства и стоимости рекламно-сувенирной и полиграфической продукции, обозначенной товарными знаками ПАО &laquo;ЛУКОЙЛ&raquo;, для оформления розничных торговых точек, станций сервисного обслуживания, а также обеспечения клиентов дилеров с 15 марта 2021 года дилер может оформить заказ на ЛУКМАРКЕТ только в период с 1-10 число месяца, следующего за месяцем начисления маркетингового ретробонуса.</span></p>

          <p style="text-align:justify"><span style="font-size:16px">Опция самостоятельного производства маркетинговых материалов силами дилеров ООО &laquo;ЛЛК-Интернешнл&raquo;, макеты которых доступны на спецресурсе для дилеров spec.lukoil-masla.ru, согласно письму № РА-545 от 22.09.2020 &laquo;Об изменении правил использования части маркетингового ретробонуса, начисляемой для закупки рекламно-сувенирной продукции ЛУКМАРКЕТ&raquo;, остается без изменений.</span></p>

          <p style="text-align:justify"><span style="font-size:16px">Надеемся на продуктивное сотрудничество по обеспечению розничных торговых точек, станций технического обслуживания рекламными материалами с товарными знаками ПАО &laquo;ЛУКОЙЛ&raquo;.</span></p>

          <hr />
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersCurrentlyUnavailable"
}
</script>

<style scoped>
    .section-items {
        line-height: 1.4;
    }
</style>
