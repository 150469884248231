<template>
    <div>
        <banner/>
        <info v-if="getConfigValue('isOrderCreatingDisabled')"/>
        <popular-products/>
        <catalog></catalog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Banner from "@/components/main_page/Banner";
import PopularProducts from "@/components/main_page/PopularProducts";
import Catalog from "@/components/main_page/Catalog";
import OrdersCurrentlyUnavailable from "@/components/main_page/OrdersCurrentlyUnavailable";

export default {
    name: "Home",
    components: {
        Banner,
        PopularProducts,
        Catalog,
        'info': OrdersCurrentlyUnavailable
    },
    created() {
        this.fetchPopularProducts();
    },
    methods: {
        ...mapActions('main_page', ['fetchPopularProducts'])
    },
    computed: {
        ...mapGetters('common', ['getConfigValue'])
    },
    metaInfo() {
        return {
            title: `Главная - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>

</style>
