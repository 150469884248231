<template>
  <div>
    <div class="container container--out-pad">
      <div class="row">
        <div class="col-xl-12">
          <h2 class="title"><span>Каталог</span></h2>
        </div>
      </div>
    </div>
    <div class="catalog-links-container container container--out-pad">
      <div class="row">
        <div :key="key" v-for="(section, key) in sections" class="widget-wrapper">
          <router-link :to="{name: 'Catalog', params: {slug: section.seo_path}}" class="widget">
                <span class="widget__img-place">
                    <img :src="require(`@/assets/images/${section.img_src}`)" alt="image"
                         class="img-fluid widget__img"/>
                </span>
            <p class="cat-title widget__title">{{ section.name }}</p>
          </router-link>
        </div>
        <div class="widget-wrapper">
          <router-link class="widget" :to="{name: 'Visits'}">
                      <span class="widget__img-place">
                          <img src="@/assets/images/block3_visits1.jpg" alt="image" class="img-fluid widget__img"/>
                      </span>
            <p class="cat-title widget__title">Поездки</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Catalog",
  data() {
    return {
      sections: [
        {
          seo_path: 'materialy-dlya-oformleniya-sto-i-rtt',
          img_src: 'sto_rtt.jpg',
          name: 'Материалы для оформления СТО и РТТ'
        },
        {
          seo_path: 'oborudovanie-dlya-sto-i-rtt',
          img_src: 'sto_rtt3.jpg',
          name: 'Оборудование для СТО и РТТ'
        },
        {
          seo_path: 'odezhda',
          img_src: 'block2_poligrafia1.jpg',
          name: 'Одежда'
        },
        {
          seo_path: 'poligrafiya',
          img_src: 'poligr.jpg',
          name: 'Полиграфия'
        },
        {
          seo_path: 'suvenirnaya-produkciya-i-oborudovanie',
          img_src: 'block1_suvenirnaya-produkciya.png',
          name: 'Сувенирная продукция и оборудование'
        },
        {
          seo_path: 'tovary-dlya-promoakcij',
          img_src: 'promo.jpg',
          name: 'Товары для промоакций'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.container--out-pad {
  &:after {
    content: "";
    display: block;
    width: 100%;
  }

  padding-left: 30px !important;
  padding-right: 30px !important;
  clear: both;
  height: auto !important;
  min-height: auto !important;
}


/*
.widget-link {
  text-decoration: none !important;
  transition: all 0.3s linear;
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.widget-img:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
*/

/* .widget-link:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #000;
} */

/*
.widget-link:hover .cat-title {
  text-decoration: none !important;
  color: #d2233c;
}

.cat-title {
  transition: color 0.3s linear;
}
*/

.widget-wrapper {
  margin-right: 6px;

  &:first-child {
    margin-right: 6px;
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    margin-left: 6px
  }
}

.widget {
  text-decoration: none !important;
}

.widget__img-place {
  display: block;
  position: relative;
  width: 140px;
  top: 0;
  left: 0;
}

.widget:hover .widget__img-place:after {
  opacity: 0.3;
}

.widget__img-place:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.widget__img {
  display: block;
}

.widget__title {
  transition: color 0.3s ease;
  width: 140px;
}

.widget:hover .widget__title {
  color: #d2233c !important;
}

.catalog-links-container {
  display: flex;
  justify-content: center;
}

.catalog-links-container.container--out-pad:after {
  content: none;
}

@media(max-width: 1199px) {
  .catalog-links-container .row {
    padding-left: 12px;
  }
}

@media(max-width: 991px) {
  .catalog-links-container .row .widget-wrapper {
    margin-right: 15px;
  }
}

@media(max-width: 769px) {
  .catalog-links-container {
    display:flex;
    justify-content: center;
  }

  .catalog-links-container .row {
    //width: 68.2%;
    width: 100%;
    padding-left: 0;
  }

  .catalog-links-container .row .widget-wrapper:nth-child(3n) {
    margin-right: 0 !important;
  }
}



@media(max-width: 600px) {
  .catalog-links-container {
    display:flex;
    justify-content: center;
  }

  .catalog-links-container .row {
    justify-content: space-between;
    padding-left: 0;
  }

  .catalog-links-container .row .widget-wrapper:nth-child(2n) {
    margin-right: 0 !important;
  }
}

@media(max-width: 500px) {
  .catalog-links-container .row {
    max-width: 80%;
  }
}

@media(max-width: 432px) {
  .catalog-links-container .row {
    max-width: 90%;
  }
}

@media(max-width: 432px) {
  .catalog-links-container .row {
    max-width: 90%;
  }
}

@media (max-width: 399px) {
  .catalog-links-container .row {
    max-width: 100%;
  }
}

@media (max-width: 359px) {
  .catalog-links-container .row {
    flex-direction: column;
    align-items: center;
  }
}

</style>